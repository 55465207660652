// action - state management
import { SET_ESRI_SCRAPER_ROOT, UNSET_ESRI_SCRAPER_ROOT } from './actions';

export const initialState = {
    sourceURL: '',
    referer: '',
    cookie: ''
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const esriScraperRootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ESRI_SCRAPER_ROOT: {
            const { sourceURL, referer, cookie } = action.payload;
            return {
                ...state,
                sourceURL,
                referer,
                cookie
            };
        }
        case UNSET_ESRI_SCRAPER_ROOT: {
            const { } = action.payload;
            return {
                ...state,
                sourceURL: '',
                referer: '',
                cookie: ''
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default esriScraperRootReducer;
