import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import io from 'socket.io-client';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CardActions,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@material-ui/core';



import Modal from "../../../../modal";
import StdOutView from "../../../../views/workFlows/components/stdoutView";

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';
import NotificationList from './NotificationList';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ADD_MESSAGE, REMOVE_MESSAGE, CLEAR_MESSAGES } from '../../../../store/actions';

// assets
import { 
    IconBell,
    IconBellRinging
} from '@tabler/icons';

// style constant
const useStyles = makeStyles((theme) => ({
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 205px)',
        overflowX: 'hidden'
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        '&[aria-controls="menu-list-grow"],&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light
        }
    },
    cardContent: {
        padding: '0px !important'
    },
    notificationChip: {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.warning.dark
    },
    divider: {
        marginTop: 0,
        marginBottom: 0
    },
    cardAction: {
        padding: '10px',
        justifyContent: 'center'
    },
    paddingBottom: {
        paddingBottom: '16px'
    },
    box: {
        marginLeft: '6px',
        marginRight: '6px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '6px'
        }
    },
    bodyPPacing: {
        padding: '16px 16px 0'
    },
    textBoxSpacing: {
        padding: '0px 16px'
    }
}));

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

//-----------------------|| NOTIFICATION ||-----------------------//

const NotificationSection = () => {

    const dispatcher = useDispatch();
    const account = useSelector((state) => state.account);
    const messageState = useSelector((state) => state.messages) || {messages:[]};
    
    const workFlowStdOutState = useSelector((state) => state.workFlowStdOut) || {ids:[]};

    const messages = (messageState.messages || [])
    const stdOutIds = (workFlowStdOutState.ids || [])

    console.log({account,messages,stdOutIds})
    const classes = useStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [socket, setSocket] = React.useState(null)
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [isNewMessage, setIsNewMessage] = React.useState(false);
    
    const [showStdOutModal, setShowStdOutModal] = React.useState(false);
    
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        if(!prevOpen.current){
            setIsNewMessage(false)
        }
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setIsNewMessage(false)
        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        
        if(isLoaded){
            if (prevOpen.current === true && open === false) {
                anchorRef.current.focus();
            }
            prevOpen.current = open;
        }
    }, [open]);

    React.useEffect(() => {
        if(isLoaded){
            setIsNewMessage(false)
            setOpen(false);
            if (!stdOutIds || !stdOutIds.length) {
                setShowStdOutModal(false)
            }
            else{
                
                setShowStdOutModal(true)
            }
        }
    }, [stdOutIds]);
    
    React.useEffect(() => {
        const newSocket = io({
            path: '/api/socket.io'
            // 'reconnection': true, 
            // transports: ['websocket']
        });
        
        newSocket.on("connect", () => {
            console.log(newSocket.connected); // true
        });
          
        newSocket.on("disconnect", () => {
            console.log(newSocket.connected); // false
        });
        
        if(account && account.user && account.user.id){
            newSocket.on(account.user.id, function(data){
                console.log({newMessage:data})
                setIsNewMessage(true)
                dispatcher({
                    type: ADD_MESSAGE,
                    payload: { message: data }
                });
            });

        }
        newSocket.connect()
        console.log({newSocket,dt:true})
        setSocket(newSocket);
        window.setTimeout(()=>{
            setIsLoaded(true)
        },500)
        // return () => newSocket.close();
    }, [setSocket]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    let currentBellIcon = <IconBell stroke={1.5} size="1.3rem" />
    if(isNewMessage){
        currentBellIcon = (
            <div>
                <IconBellRinging className="shake" stroke={1.5} size="1.3rem" />
            </div>
        )
    }

    return (
        <React.Fragment>
            {stdOutIds && stdOutIds.length && (
                
            <Box component="span" className={classes.box}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        className={classes.headerAvatar}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        // aria-haspopup="true"
                        onClick={(e)=>{
                            e.preventDefault()
                            setShowStdOutModal(true)
                        }}
                        color="inherit"
                        style={{fontSize:'.9em'}}
                    >
                        <FontAwesomeIcon icon={solid('terminal')} />
                    </Avatar>
                </ButtonBase>
            </Box>
            ) || null}
            {/* <Box component="span" className={classes.box}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        {currentBellIcon}
                    </Avatar>
                </ButtonBase>
            </Box> */}
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <div className={classes.bodyPPacing}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>
                                                            <Stack direction="row" spacing={2}>
                                                                <Typography variant="subtitle1">All Notification</Typography>
                                                                <Chip size="small" label={messages.length} className={classes.notificationChip} />
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography component={Link} to="#" variant="subtitle2" color="primary" onClick={(e)=>{
                                                                e.preventDefault()
                                                                
                                                                dispatcher({
                                                                    type: CLEAR_MESSAGES,
                                                                    payload: {  }
                                                                });
                                                            }}>
                                                                Clear all
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar className={classes.ScrollHeight}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <div className={classes.textBoxSpacing}>
                                                                <TextField
                                                                    id="outlined-select-currency-native"
                                                                    select
                                                                    fullWidth
                                                                    value={value}
                                                                    onChange={handleChange}
                                                                    SelectProps={{
                                                                        native: true
                                                                    }}
                                                                >
                                                                    {status.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider className={classes.divider} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <NotificationList />
                                                        </Grid>
                                                    </Grid>
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider />
                                    <CardActions className={classes.cardAction}>
                                        <Button size="small" disableElevation>
                                            View All
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>

            {stdOutIds && stdOutIds.length && showStdOutModal && (
                
				<Modal>
                    <div className="modal">

                        <div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
                            onClick={(e)=>{
                                // if(open){
                                //     window.setTimeout(()=>{
                                //         setOpen(true)
                                //     },100)
                                // }
                                setShowStdOutModal(false)
                            }}>

                        </div>
                        <div style={{position:'relative',background:'transparent',width:'70vw',height:'74vmin',zIndex:1000}}>
                            <StdOutView />
                        </div>
                    </div>
                </Modal>
            ) || null}
        </React.Fragment>
    );
};

export default NotificationSection;
