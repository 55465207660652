// action - state management
import { SET_PROJECT, UNSET_PROJECT } from './actions';

export const initialState = {
    name: null,
    crs: null
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT: {
            const { name, crs } = action.payload;
            return {
                ...state,
                name,
                crs
            };
        }
        case UNSET_PROJECT: {
            const { name } = action.payload;
            return {
                ...state,
                name: null,
                crs: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default projectReducer;
