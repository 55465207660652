// action - state management
import { SET_HAS_CONTEXTUAL_SEARCH,SET_CONTEXTUAL_SEARCH_STRING, SET_CONTEXTUAL_SEARCH_PLACEHOLDER  } from './actions';

export const initialState = {
    contextualSearch: false,
    contextualSearchString: '',
    contextualSearchPlaceholder: ''
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const sitewideFeatureReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HAS_CONTEXTUAL_SEARCH: {
            const { contextualSearch,contextualSearchString, contextualSearchPlaceholder } = action.payload;
            return {
                ...state,
                contextualSearch,
                contextualSearchString:contextualSearchString||'',
                contextualSearchPlaceholder:contextualSearchPlaceholder||'Search'
            };
        }
        case SET_CONTEXTUAL_SEARCH_STRING: {
            const {contextualSearchString } = action.payload;
            return {
                ...state,
                contextualSearchString:contextualSearchString||''
            };
        }
        case SET_CONTEXTUAL_SEARCH_PLACEHOLDER: {
            const {contextualSearchPlaceholder } = action.payload;
            return {
                ...state,
                contextualSearchPlaceholder:contextualSearchPlaceholder||'Search'
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default sitewideFeatureReducer;
