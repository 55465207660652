// action - state management
import { ADD_MESSAGE, REMOVE_MESSAGE, CLEAR_MESSAGES } from './actions';

export const initialState = {
    messages: []
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MESSAGE: {
            const { message } = action.payload;
            return {
                ...state,
                messages: state.messages.filter((m)=>{return m.id !== message.id}).concat(message)
            };
        }
        case REMOVE_MESSAGE: {
            const { id } = action.payload;
            return {
                ...state,
                messages: state.messages.filter((m)=>{return m.id !== id})
            };
        }
        case CLEAR_MESSAGES: {
            // const { id } = action.payload;
            return {
                ...state,
                messages: []
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default messageReducer;
