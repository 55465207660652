// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

export const SET_HAS_CONTEXTUAL_SEARCH = 'SET_HAS_CONTEXTUAL_SEARCH'
export const SET_CONTEXTUAL_SEARCH_STRING = 'SET_CONTEXTUAL_SEARCH_STRING'
export const SET_CONTEXTUAL_SEARCH_PLACEHOLDER = 'SET_CONTEXTUAL_SEARCH_PLACEHOLDER'

// action - project reducer
export const SET_PROJECT = 'SET_PROJECT';
export const UNSET_PROJECT = 'UNSET_PROJECT';

// action - workflow reducer
export const SET_WORKFLOW = 'SET_WORKFLOW';
export const UNSET_WORKFLOW = 'UNSET_WORKFLOW';

// action - message reducer
// ADD_MESSAGE, REMOVE_MESSAGE, CLEAR_MESSAGES
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

// action - workflow stdout reducer
// ADD_WORKFLOW_STDOUT_ID, REMOVE_WORKFLOW_STDOUT_ID, CLEAR_WORKFLOW_STDOUT_IDS, SELECT_WORKFLOW_STDOUT_ID
export const ADD_WORKFLOW_STDOUT_ID = 'ADD_WORKFLOW_STDOUT_ID'
export const REMOVE_WORKFLOW_STDOUT_ID = 'REMOVE_WORKFLOW_STDOUT_ID'
export const CLEAR_WORKFLOW_STDOUT_IDS = 'CLEAR_WORKFLOW_STDOUT_IDS'
export const SELECT_WORKFLOW_STDOUT_ID = 'SELECT_WORKFLOW_STDOUT_ID'

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - esriScraperRootReducer
export const SET_ESRI_SCRAPER_ROOT = 'SET_ESRI_SCRAPER_ROOT'
export const UNSET_ESRI_SCRAPER_ROOT = 'UNSET_ESRI_SCRAPER_ROOT'


// action - gisDataMapReducer
export const SET_GIS_CONFIG_ID = 'SET_GIS_MAP_CONFIG_ID'
export const SET_GIS_CONFIGS = 'SET_GIS_MAP_CONFIGS'
export const CLEAR_GIS_CONFIGS = 'CLEAR_GIS_MAP_CONFIGS'

// action - chartDataReducer
export const SET_CHART_CONFIG_ID = 'SET_CHART_CONFIG_ID'
export const SET_CHART_CONFIGS = 'SET_CHART_CONFIGS'
export const CLEAR_CHART_CONFIGS = 'CLEAR_CHART_CONFIG'
