// assets

import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// console.log({useDispatch,useSelector})

import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd, IconSettings, IconMap2, IconChartLine, IconCode, IconNotebook, IconCloudDownload, IconDatabase } from '@tabler/icons';

// const project2 = useSelector((state) => state.project);
// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconSettings:IconSettings,
    IconMap2:IconMap2,
    IconChartLine:IconChartLine,
    IconCode:IconCode,
    IconNotebook:IconNotebook,
    IconCloudDownload:IconCloudDownload,
    IconDatabase:IconDatabase
};

//-----------------------|| PROJECT MENU ITEMS ||-----------------------//

const ProjectMenuItems = () => {

    // const project = useSelector((state) => state.project);

    let projName = 'test'
    // if(project && project.name){
    //     projName = project.name
    // }

    return {
        id: 'project',
        title: 'Project',
        type: 'group',
        children: [
            // {
            //     id: 'project-data',
            //     title: 'Data',
            //     type: 'item',
            //     url: '/projectData',
            //     icon: icons['IconDatabase'],
            //     breadcrumbs: false
            // },
            // {
            //     id: 'workflows',
            //     title: 'Workflows',
            //     type: 'item',
            //     url: '/workflows',
            //     icon: icons['IconSettings'],
            //     breadcrumbs: false
            // },
            {
                id: 'project-gis',
                title: 'GIS',
                type: 'item',
                url: '/gis',
                icon: icons['IconMap2'],
                breadcrumbs: false
            },
            // {
            //     id: 'project-chart',
            //     title: 'Charting',
            //     type: 'item',
            //     url: '/charting',
            //     icon: icons['IconChartLine'],
            //     breadcrumbs: false
            // },

            // {
            //     id: 'project-pickup',
            //     title: 'Pickup Files',
            //     type: 'item',
            //     url: '/projectData',
            //     icon: icons['IconCloudDownload'],
            //     breadcrumbs: false
            // },

            // {
            //     id: 'project-env',
            //     title: 'Environments',
            //     type: 'item',
            //     url: '/projectData',
            //     icon: icons['IconCode'],
            //     breadcrumbs: false
            // },
            // {
            //     id: 'project-note',
            //     title: 'Notes',
            //     type: 'item',
            //     url: '/notes',
            //     icon: icons['IconNotebook'],
            //     breadcrumbs: false
            // },
            // {
            //     id: 'util-shadow',
            //     title: 'Shadow',
            //     type: 'item',
            //     url: '/utils/util-shadow',
            //     icon: icons['IconShadow'],
            //     breadcrumbs: false
            // },
            // {
            //     id: 'icons',
            //     title: 'Icons',
            //     type: 'collapse',
            //     icon: icons['IconWindmill'],
            //     children: [
            //         {
            //             id: 'tabler-icons',
            //             title: 'Tabler Icons',
            //             type: 'item',
            //             url: '/icons/tabler-icons',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'material-icons',
            //             title: 'Material Icons',
            //             type: 'item',
            //             url: '/icons/material-icons',
            //             breadcrumbs: false
            //         }
            //     ]
            // }
        ]
    };
}

export const project =  ProjectMenuItems();

// export const project = {
//     id: 'project',
//     title: 'Utilities',
//     type: 'group',
//     children: [
//         {
//             id: 'util-typography',
//             title: 'Typography',
//             type: 'item',
//             url: '/utils/util-typography',
//             icon: icons['IconTypography'],
//             breadcrumbs: false
//         },
//         {
//             id: 'util-color',
//             title: 'Color',
//             type: 'item',
//             url: '/utils/util-color',
//             icon: icons['IconPalette'],
//             breadcrumbs: false
//         },
//         {
//             id: 'util-shadow',
//             title: 'Shadow',
//             type: 'item',
//             url: '/utils/util-shadow',
//             icon: icons['IconShadow'],
//             breadcrumbs: false
//         },
//         {
//             id: 'icons',
//             title: 'Icons',
//             type: 'collapse',
//             icon: icons['IconWindmill'],
//             children: [
//                 {
//                     id: 'tabler-icons',
//                     title: 'Tabler Icons',
//                     type: 'item',
//                     url: '/icons/tabler-icons',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'material-icons',
//                     title: 'Material Icons',
//                     type: 'item',
//                     url: '/icons/material-icons',
//                     breadcrumbs: false
//                 }
//             ]
//         }
//     ]
// };
