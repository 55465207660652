import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ADD_MESSAGE, REMOVE_MESSAGE, CLEAR_MESSAGES, ADD_WORKFLOW_STDOUT_ID } from '../../../../store/actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@material-ui/core';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from './../../../../assets/images/users/user-round.svg';

// style constant
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '330px',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px'
        }
    },
    listAction: {
        top: '22px'
    },
    actionColor: {
        color: theme.palette.grey[500]
    },

    listItem: {
        padding: 0
    },
    sendIcon: {
        marginLeft: '8px',
        marginTop: '-3px'
    },
    listDivider: {
        marginTop: 0,
        marginBottom: 0
    },
    listChipError: {
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        height: '24px',
        padding: '0 6px',
        marginRight: '5px'
    },
    listChipWarning: {
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light,
        height: '24px',
        padding: '0 6px'
    },
    listChipSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: '24px',
        padding: '0 6px'
    },
    listAvatarSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        border: 'none',
        borderColor: theme.palette.success.main
    },
    listAvatarPrimary: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
        border: 'none',
        borderColor: theme.palette.primary.main
    },
    listContainer: {
        paddingLeft: '56px'
    },
    uploadCard: {
        backgroundColor: theme.palette.secondary.light
    },
    paddingBottom: {
        paddingBottom: '16px'
    },
    itemAction: {
        cursor: 'pointer',
        padding: '6px',
        '&:hover': {
            background: theme.palette.primary.light
        }
    }
}));

//-----------------------|| NOTIFICATION LIST ITEM ||-----------------------//
function when(time){
    let now = new Date().getTime()
    let diff = now-time
    let seconds = diff/(1000)
    let minutes = diff/(60*1000)
    let hours = diff/(60*60*1000)
    let days = diff/(24*60*60*1000)
    let weeks = diff/(7*24*60*60*1000)
    let months = diff/(30*24*60*60*1000)
    let years = diff/(365*24*60*60*1000)
    if(minutes<2){
        return 'Just Now'
    }
    if(minutes<120){
        return minutes.toFixed(0) + ' minutes ago'
    }
    if(hours<48){
        return hours.toFixed(0) + ' hours ago'
    }
    if(days<14){
        return days.toFixed(0) + ' days ago'
    }
    if(weeks<8){
        return weeks.toFixed(0) + ' weeks ago'
    }
    if(months<24){
        return months.toFixed(0) + ' months ago'
    }
    return years.toFixed(0) + ' years ago'
}
const NotificationList = () => {

    const dispatcher = useDispatch();
    const classes = useStyles();
    const messageState = useSelector((state) => state.messages) || {messages:[]};
    const messages = (messageState.messages || [])


    const [openGroups, setOpenGroups] = React.useState([]);


    if(messages && messages.length){
        messages.sort((a,b)=>{
            if(a.timestamp<b.timestamp){
                return 1
            }
            else if(a.timestamp>b.timestamp){
                return -1
            }
            else{
                return 0
            }
        })
    }
    console.log({test:true,messages})

    return (
        <List className={classes.navContainer}>
            
            {messages.map((msg,mx)=>{
                console.log(msg)
                let message = msg.payload
                let icon = solid('coffee')
                let iconStyle={}
                switch(message.type){
                    case 'import':
                        icon = solid('file-import')
                        break;
                    
                    case 'export':
                        icon = solid('file-export')
                        break;
                
                    // case 'project':
                    //     icon = solid('rectangle-history-circle-plus')
                    //     break;
            
                    // case 'arcMapServerImport':
                    //     icon = solid('arrow-up-from-arc')
                    //     iconStyle.transform='rot(180deg)'
                    //     break;

                    default:
                        icon = solid('box')
                        // style.transform='rot(180deg)'
                        break;
                                
                }
                let iconSize=26
                return (
                    <React.Fragment key={'msg_'+mx}>

                        <div className={classes.itemAction}>
                            <ListItem alignItems="center" className={classes.listItem}>

                                <div style={{
                                    background:'#2196f3',
                                    color:'white', 
                                    width:'100%', 
                                    height:iconSize+'px',
                                    textAlign:'left',
                                    paddingLeft:'8px',
                                    verticalAlign:'center',
                                    // borderRadius:(iconSize/2).toFixed(0)+'px '+ (iconSize/2).toFixed(0)+'px 0px 0px ',
                                    paddingTop:(iconSize/6).toFixed(0)+'px'}}>
                                        {/*                                         
                                        <FontAwesomeIcon icon={solid('coffee')} /> 
                                        */}

                                        <FontAwesomeIcon icon={icon} style={iconStyle} />
                                        <span style={{marginLeft:'8px'}}>{message.from}</span>
                                </div>
{/* 
                                <ListItemAvatar>
                                    <div style={{background:'#2196f3',color:'white', width:(iconSize*1.6).toFixed()+'px', height:iconSize+'px',textAlign:'center',verticalAlign:'center',borderRadius:(iconSize/2).toFixed(0)+'px',paddingTop:(iconSize/6).toFixed(0)+'px'}}>
                                        <FontAwesomeIcon icon={icon} style={style} />
                                    </div>
                                    
                                    <Avatar alt={message.from} src={User1} />
                                </ListItemAvatar>
                                
                                <ListItemText primary={<Typography variant="subtitle1">{message.from}</Typography>} />
 
                                <ListItemSecondaryAction className={classes.listAction}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12}>
                                            <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                                {when(msg.timestamp)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                                 */}
                            </ListItem>
                            <Grid container direction="column" className={classes.listContainer}>
                                <Grid item xs={12} className={classes.paddingBottom}>
                                    <Typography variant="subtitle1">
                                        {message.title}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {message.body}
                                    </Typography>
                                    <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                        {when(msg.timestamp)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                    {message && message.stdout && (
                                        <Grid item>
                                            <Chip label="stdout" className={classes.listChipError} onClick={(e)=>{
                                                console.log('stdout ' + message.stdout)
                                                // e.preventDefault()
                                                
                                                dispatcher({
                                                    type: ADD_WORKFLOW_STDOUT_ID,
                                                    payload: { id:message.stdout }
                                                });
                                            }} />
                                        </Grid>

                                    )}
                                        <Grid item>
                                            <Chip label="Clear" className={classes.listChipError} onClick={(e)=>{
                                                console.log('clear ' + msg.id)
                                                e.preventDefault()
                                                
                                                dispatcher({
                                                    type: REMOVE_MESSAGE,
                                                    payload: { id:msg.id }
                                                });
                                            }} />
                                        </Grid>
{/*                                         
                                        <Grid item>
                                            <Chip label="Error" className={classes.listChipError} />
                                        </Grid>
                                        <Grid item>
                                            <Chip label="Warning" className={classes.listChipWarning} />
                                        </Grid>
                                        <Grid item>
                                            <Chip label="Success" className={classes.listChipSuccess} />
                                        </Grid>
                                             */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>

                        <Divider className={classes.listDivider} />
                    </React.Fragment>
                
                )
            })}
{/* 
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} />
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
                    <ListItemSecondaryAction className={classes.listAction}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12} className={classes.paddingBottom}>
                        <Typography variant="subtitle2">It is a long established fact that a reader will be distracted</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Unread" className={classes.listChipError} />
                            </Grid>
                            <Grid item>
                                <Chip label="New" className={classes.listChipWarning} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <Divider className={classes.listDivider} />

            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarSuccess}>
                            <IconBuildingStore stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">Store Verification Done</Typography>} />
                    <ListItemSecondaryAction className={classes.listAction}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12} className={classes.paddingBottom}>
                        <Typography variant="subtitle2">We have successfully received your request.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Unread" className={classes.listChipError} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarPrimary}>
                            <IconMailbox stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">Check Your Mail.</Typography>} />
                    <ListItemSecondaryAction className={classes.listAction}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12} className={classes.paddingBottom}>
                        <Typography variant="subtitle2">All done! Now check your inbox as you're in for a sweet treat!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button variant="contained" disableElevation>
                                    Mail
                                    <IconBrandTelegram stroke={1.5} size="1.3rem" className={classes.sendIcon} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} />
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
                    <ListItemSecondaryAction className={classes.listAction}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12} className={classes.paddingBottom}>
                        <Typography component="span" variant="subtitle2">
                            Uploaded two file on &nbsp;
                            <Typography component="span" variant="h6">
                                21 Jan 2020
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Card className={classes.uploadCard}>
                                    <CardContent>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <Stack direction="row" spacing={2}>
                                                    <IconPhoto stroke={1.5} size="1.3rem" />
                                                    <Typography variant="subtitle1">demo.jpg</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} />
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
                    <ListItemSecondaryAction className={classes.listAction}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className={classes.listContainer}>
                    <Grid item xs={12} className={classes.paddingBottom}>
                        <Typography variant="subtitle2">It is a long established fact that a reader will be distracted</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Confirmation of Account." className={classes.listChipSuccess} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div> */}


        </List>
    );
};

export default NotificationList;
