import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import { ADD_WORKFLOW_STDOUT_ID, REMOVE_WORKFLOW_STDOUT_ID, CLEAR_WORKFLOW_STDOUT_IDS, SELECT_WORKFLOW_STDOUT_ID } from '../../../store/actions';


//-----------------------|| DEFAULT DASHBOARD ||-----------------------//

const StdOutView = () => {


    const dispatcher = useDispatch();
    
    const workFlowStdOutState = useSelector((state) => state.workFlowStdOut) || {ids:[]};
    const stdOutIds = (workFlowStdOutState.ids || [])
    const selectedStdOutId = (workFlowStdOutState.selected || null)

    console.log({stdOutIds,selectedStdOutId})
    const account = useSelector((state) => state.account);
    // const project = useSelector((state) => state.project);
    // const [search, setSearch] = React.useState([]);

    const defaultWorkflow = {
        details:{},
        stdOut:'[NOT FOUND]'
    }

    const [workFlow, setWorkFlow] = React.useState(defaultWorkflow);
    const [isLoading, setLoading] = useState(true);

    const getWorkFlowDetails = async () => {
        console.log({getWorkFlowDetails:selectedStdOutId})
        if(selectedStdOutId){
            let wfId = selectedStdOutId.replace('_stdout','')

            axios.get('/api/workflows/stats/'+wfId,{ 
                headers: { Authorization: `${account.token}` 
                } 
            })
            .then((wfResponse)=>{
                axios.get('/api/workflows/stats/'+selectedStdOutId,{ 
                    headers: { Authorization: `${account.token}` 
                    } 
                })
                .then((wfsoResponse)=>{
                    console.log({wfResponse,wfsoResponse})
                    let wfso = wfsoResponse.data
                    if (typeof wfso === 'object' || wfso instanceof Object){
                        wfso = JSON.stringify(wfso, null, 2)
                    }
                    console.log({
                        details:wfResponse.data,
                        stdOut:wfso
                    })
                    setWorkFlow({
                        details:wfResponse.data,
                        stdOut:wfso
                    });
                })
                if(wfResponse && wfResponse.data && wfResponse.data.status!='terminated'){
                    window.setTimeout(()=>{
                        getWorkFlowDetails()
                    },200)
                }
            })
            .catch((err)=>{
                setWorkFlow(defaultWorkflow);
            })
        }
    };


    useEffect(() => {
        // getWorkFlows();
        setLoading(false);
    }, []);
    useEffect(() => {
        getWorkFlowDetails();
    }, [selectedStdOutId]);


    return (
        <React.Fragment>
            <div>                
                <div style={{
                    zIndex:996,
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    right:'0px',
                    height:'50px',
                    // maxWidth:'9999px',
                    maxHeight:'51px',
                    overflowY:'hidden',
                    overflowX:'scroll',
                    whiteSpace:'nowrap',
                    padding:'2px'
                    
                }}>
                    {stdOutIds.map((id,idx)=>{
                        return (
                            <div
                            key={'tab_'+idx} 
                            style={{
                                display: 'inline-block',
                                border: 'solid 1px black',
                                padding:' 5px',
                                borderBottom: 'solid 0px black',
                                borderRadius: '5px 5px 0px 0px',
                                marginLeft: '-2px',
                                background: (selectedStdOutId===id?'#2196f3':'#CDCDCD'),
                                color: (selectedStdOutId===id?'white':'black'),
                                fontWeight: (selectedStdOutId===id?'bold':'normal')
                                
                            }}>
                                <div style={{
                                    cursor:'pointer',
                                    display: 'inline-block'
                                }} onClick={(e)=>{
                                        
                                        dispatcher({
                                            type: SELECT_WORKFLOW_STDOUT_ID,
                                            payload: { id }
                                        });
                                    }}>
                                        {id.split('_').slice(2).join('_')}
                                </div>
                                <div style={{
                                    cursor:'pointer',
                                    display: 'inline-block',
                                    marginLeft:'5px',
                                    border:(selectedStdOutId===id?'solid 1px white':'solid 1px rgba(128,128,128,1)'),
                                    borderRadius:'5px',
                                    height:'16px',
                                    width:'16px',
                                    textAlign:'center',
                                    fontSize:'12px',
                                    fontWeight: 'normal',
                                    lineHeight:'12px'}} onClick={(e)=>{
                                        
                                        dispatcher({
                                            type: REMOVE_WORKFLOW_STDOUT_ID,
                                            payload: { id }
                                        });
                                    }}>
                                    x
                                </div>
                                
                            </div>
                        )
                    })}
                
                </div>
                <div style={{
                    zIndex:998,
                    position:'absolute',
                    top:'30px',
                    left:'0px',
                    right:'0px',
                    bottom:'0px',
                    // marginLeft:'2px',
                    // marginRight:'2px',
                    border:'solid 1px black',
                    borderRadius: '0px 5px 5px 5px',
                    width:'100%',
                    overflowY:'overlay',
                    overflowX:'hidden',
                    padding:'2px',
                    background: 'black'
                }}>
                    <div style={{
                        position:'absolute',
                        top:'0px',
                        left:'0px',
                        right:'0px',
                        bottom:'0px',
                        // background:'green'                        
                    }}>
                        <div style={{
                            position:'absolute',
                            padding: '2px 5px',
                            top:'0px',
                            left:'0px',
                            right:'0px',
                            height:'20px',
                            background:'#2196f3',
                            color:'white'
                        
                        }}>
                            STDOUT from {selectedStdOutId}: {(workFlow.details || {}).message}
                        </div>
                        <div style={{
                            position:'absolute',
                            top:'20px',
                            left:'0px',
                            right:'0px',
                            bottom:'0px',
                            width:'100%',
                            background:'black',
                            color:'white',
                            fontSize:'.8em',
                            fontFamily:'monospace',
                            overflowY:'scroll',
                            overflowX:'hidden',
                        
                        }}>
                            {workFlow && workFlow.stdOut && (
                                <div style={{width:'100%', whiteSpace:'pre-wrap'}} >
                                    {workFlow.stdOut}
                                </div>
                            ) || null}
{/* 
                        <textarea 
                            style={{
                                    
                                    color:'white',
                                    background:'black',
                                    width:'100%',
                                    height: '100%',
                                    fontSize:'1em',
                                    fontFamily:'monospace'
                            }}
                            value={'lorem ipsum'}
                            onChange={(e)=>{
                            }}/> */}


                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default StdOutView;
