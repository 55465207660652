import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import accountReducer from './accountReducer';
import messageReducer from './messageReducer';
import projectReducer from './projectReducer';
import workFlowReducer from './workFlowReducer';
import workFlowStdOutReducer from './workFlowStdOutReducer';
import esriScraperRootReducer from './esriScraperRootReducer';
import sitewideFeatureReducer from './sitewideFeatureReducer';
import gisDataReducer from './gisDataReducer';
import chartDataReducer from './chartDataReducer';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage,
            keyPrefix: 'flow-'
        },
        accountReducer
    ),
    sitewide: persistReducer(
        {
            key: 'sitewide',
            storage,
            keyPrefix: 'flow-'
        },
        sitewideFeatureReducer
    ),
    messages: persistReducer(
        {
            key: 'messages',
            storage,
            keyPrefix: 'flow-'
        },
        messageReducer
    ),
    project: persistReducer(
        {
            key: 'project',
            storage,
            keyPrefix: 'flow-'
        },
        projectReducer
    ),
    workFlow: persistReducer(
        {
            key: 'workFlow',
            storage,
            keyPrefix: 'flow-'
        },
        workFlowReducer
    ),
    workFlowStdOut: persistReducer(
        {
            key: 'workFlowStdOut',
            storage,
            keyPrefix: 'flow-'
        },
        workFlowStdOutReducer
    ),
    esriScraperRoot: persistReducer(
        {
            key: 'esriScraperRoot',
            storage,
            keyPrefix: 'flow-'
        },
        esriScraperRootReducer
    ),
    gisData: persistReducer(
        {
            key: 'gisData',
            storage,
            keyPrefix: 'flow-'
        },
        gisDataReducer
    ),
    chartData: persistReducer(
        {
            key: 'chartData',
            storage,
            keyPrefix: 'flow-'
        },
        chartDataReducer
    ),
    customization: customizationReducer
});

export default reducer;
