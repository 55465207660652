// action - state management
import { SET_WORKFLOW, UNSET_WORKFLOW } from './actions';

export const initialState = {
    name: null
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const workFlowReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WORKFLOW: {
            const { name } = action.payload;
            return {
                ...state,
                name
            };
        }
        case UNSET_WORKFLOW: {
            const { name } = action.payload;
            return {
                ...state,
                name: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default workFlowReducer;
