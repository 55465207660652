// action - state management
import { SET_CHART_CONFIG_ID, SET_CHART_CONFIGS, CLEAR_CHART_CONFIGS } from './actions';

export const initialState = {
    currentConfigId:null,
    chartConfigs:[]
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const chartDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHART_CONFIG_ID: {
            const { id } = action.payload;
            return {
                ...state,
                currentConfigId: id
            };
        }
        case SET_CHART_CONFIGS: {
            const { configs } = action.payload;
            return {
                ...state,
                chartConfigs: configs
            };
        }
        case CLEAR_CHART_CONFIGS: {
            // const { id } = action.payload;
            return {
                ...state,
                currentConfigId: null,
                chartConfigs: []
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default chartDataReducer;
