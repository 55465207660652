import React, {useEffect, useState ,lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

import { SET_HAS_CONTEXTUAL_SEARCH } from './../store/actions';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const AdminUsers = Loadable(lazy(() => import('../views/admin/users')));
const AdminUser = Loadable(lazy(() => import('../views/admin/users/user')));
const AdminUserRoles = Loadable(lazy(() => import('../views/admin/users/roles')));


const Projects = Loadable(lazy(() => import('../views/projects')));
const Project = Loadable(lazy(() => import('../views/projects/project')));
// const ProjectData = Loadable(lazy(() => import('../views/projects/projectData')));

const GIS = Loadable(lazy(() => import('../views/gis')));
const LeafletTemplate = Loadable(lazy(() => import('../views/gis/leaflet-template')));
const GISDataMap = Loadable(lazy(() => import('../views/gis/gisDataMap')));


const Charting = Loadable(lazy(() => import('../views/charting')));
const FlowChart = Loadable(lazy(() => import('../views/charting/flowChart')));

// workflows routing
// const WorkFlows = Loadable(lazy(() => import('../views/workFlows')));
// const EsriWebScraper = Loadable(lazy(() => import('../views/workFlows/pages/esriwebscraper')));
// const BoundaryConditions = Loadable(lazy(() => import('../views/workFlows/pages/boundaryConditions')));

// notes routing
// const Notes = Loadable(lazy(() => import('../views/notes')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    
    const dispatcher = useDispatch();
    const sitewide = useSelector((state) => state.sitewide);

    const location = useLocation();
    const [isLoading, setLoading] = useState(true);
    const [lastPath, _setLastPath] = useState('');

    const lastPathRef = React.useRef(lastPath);
    const setLastPath = data => {
        lastPathRef.current = data;
        _setLastPath(data);
    };


    useEffect(() => {
        if(isLoading){
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        if(lastPathRef.current !== location.pathname){
            console.log({Mainroutes:location.pathname,sitewide})
            setLastPath(location.pathname)

            dispatcher({
                type: SET_HAS_CONTEXTUAL_SEARCH,
                payload: { 
                    contextualSearch: false,
                    contextualSearchString: ''
                }
            });
        }
    }, [location]);

    return (
        <Route
            path={[
                '/dashboard/default',
                '/admin/users',
                '/admin/user',
                '/admin/roles',


                '/projects',
                '/project',
                // '/projectData',
                
                '/gis',
                '/gis/gisDataMap',
                '/gis/template',
                
                '/charting',
                '/charting/chart',
                
                // '/workflows',
                // '/workflows/esriwebscraper',
                // '/workflows/boundaryConditions',

                // '/notes',
                
                // '/utils/util-typography',
                // '/utils/util-color',
                // '/utils/util-shadow',
                // '/icons/tabler-icons',
                // '/icons/material-icons',

                // '/sample-page'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />
                        <Route path="/admin/users" component={AdminUsers} />
                        <Route path="/admin/user" component={AdminUser} />
                        <Route path="/admin/roles" component={AdminUserRoles} />

                        <Route path="/projects" component={Projects} />
                        <Route path="/project" component={Project} />
                        {/*                         
                        <Route path="/projectData" component={ProjectData} /> 
                        */}

                        
                        <Route exact path="/gis" component={GIS} />
                        <Route path="/gis/gisDataMap" component={GISDataMap} />
                        <Route path="/gis/template" component={LeafletTemplate} />
                        
                        <Route exact path="/charting" component={Charting} />
                        <Route path="/charting/chart" component={FlowChart} />
                        
                        {/* 
                        <Route exact path="/workflows" component={WorkFlows} />
                        <Route exact path="/workflows/esriwebscraper" component={EsriWebScraper} />
                        <Route exact path="/workflows/boundaryConditions" component={BoundaryConditions} />
                        
                        <Route exact path="/notes" component={Notes} />

                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

                        <Route path="/sample-page" component={SamplePage} /> 
                        */}
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
