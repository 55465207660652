// assets
import { 
    IconDashboard, 
    IconDeviceAnalytics, 
    IconUsers, 
    IconChartRadar, 
    IconSettings 
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,
    IconUsers, 
    IconChartRadar, 
    IconSettings
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [

        // {
        //     id: 'default',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons['IconDashboard'],
        //     breadcrumbs: false
        // },
        
        {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            url: '/projects',
            icon: icons['IconChartRadar'],
            // external: true,
            // target: false,
            breadcrumbs: false
        }
    ]
};
