import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {

    const project = useSelector((state) => state.project);
    
    let filteredMenuItems = menuItem.items
    let projName = 'Project'
    if(project && project.name){
        projName = 'Project: ' + project.name
    }
    else{
        filteredMenuItems = menuItem.items.filter((mi)=>{return mi.id!=='project'})
    }

    const navItems = filteredMenuItems.map((item) => {
        // console.log({item})
        switch (item.type) {
            case 'group':
                if(item.id && item.id==='project'){
                    item.title = projName
                    item.href = "/project"
                }
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
