// action - state management
import { ADD_WORKFLOW_STDOUT_ID, REMOVE_WORKFLOW_STDOUT_ID, CLEAR_WORKFLOW_STDOUT_IDS, SELECT_WORKFLOW_STDOUT_ID } from './actions';

export const initialState = {
    ids: [],
    selected:null
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const workFlowStdOutReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_WORKFLOW_STDOUT_ID: {
            const { id } = action.payload;
            return {
                ...state,
                ids: state.ids.filter((v)=>{return v !== id}).concat(id),
                selected:id
            };
        }
        case REMOVE_WORKFLOW_STDOUT_ID: {
            const { id } = action.payload;
            let newIds = state.ids.filter((v)=>{return v !== id})
            let selected = (state.selected===id?(newIds[0]||null):state.selected)
            return {
                ...state,
                ids: newIds,
                selected
            };
        }
        case CLEAR_WORKFLOW_STDOUT_IDS: {
            // const { id } = action.payload;
            return {
                ...state,
                ids: [],
                selected:null
            };
        }
        case SELECT_WORKFLOW_STDOUT_ID: {
            const { id } = action.payload;
            return {
                ...state,
                selected:id
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default workFlowStdOutReducer;
